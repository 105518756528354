
import { defineComponent } from "vue";
import router from "@/router";
import { MonitoringKuotaItem } from "@/core/types/gws-master/dashboard/MonitoringKuotaItem";

export default defineComponent({
  name: "CardStatus",
  props: {
    title: String,
    tableData: {
      type: Array,
      default: [] as MonitoringKuotaItem[],
    },
    detailLink: String,
    tableHeader: {
      type: Array,
      default: null,
    },
  },
  components: {},
  methods: {
    handleViewDetail() {
      if (this.detailLink) {
        router.push(this.detailLink);
      }
    },
  },
});
