import ApiService from "@/core/services/ApiService";
import { MonitoringKuotaItem } from "@/core/types/gws-master/dashboard/MonitoringKuotaItem";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";

export const useDashboardStore = defineStore({
  id: "dashboardStore",
  state: () => {
    return {
      stateShowCard: false,
      stateJobFairs: [] as MonitoringKuotaItem[],
      stateP3MI: [] as MonitoringKuotaItem[],
      stateAP2TKI: [] as MonitoringKuotaItem[],
      stateVps: [] as MonitoringKuotaItem[],
      stateSvts: [] as MonitoringKuotaItem[],
      stateJobSeekers: [] as any[],
    };
  },
  getters: {
    showCard(state) {
      return state.stateShowCard;
    },
    displayJobFairs(state) {
      return state.stateJobFairs;
    },
    displayP3MI(state) {
      return state.stateP3MI;
    },
    displayAP2TKI(state) {
      return state.stateAP2TKI;
    },
    displayVps(state) {
      return state.stateVps;
    },
    displaySvts(state) {
      return state.stateSvts;
    },
    displayJobSeekers(state) {
      return state.stateJobSeekers;
    },
  },
  actions: {
    toggleShowCard() {
      this.stateShowCard = !this.stateShowCard;
    },
    async getDashboardData() {
      try {
        const responseJobFairs: AxiosResponse<ApiResponse<any>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `dashboards/master_jobfair`
          );

        const { job_fairs } = responseJobFairs.data.data || [];

        this.stateJobFairs = job_fairs.map((item) => ({
          id: item.job_fairs_id,
          name: item.job_fairs_name,
          urlId: item.quotas_id,
          used: item.quota_details_used_quota,
          total: item.quota_all,
        }));
      } catch (error) {
        console.log(error);
      }
      try {
        const responseP3mi: AxiosResponse<ApiResponse<any>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `dashboards/master_p3mi`
          );

        const { p3mi } = responseP3mi.data.data || [];

        this.stateP3MI = p3mi.map((item) => ({
          id: item.companies_id,
          name: item.companies_name,
          urlId: item.quotas_id,
          used: item.quota_details_used_quota,
          total: item.quota_all,
        }));
      } catch (error) {
        console.log(error);
      }

      try {
        const responseAp2tki: AxiosResponse<ApiResponse<any>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `dashboards/master_ap2tki`
          );

        const { ap2tki } = responseAp2tki.data.data || [];

        this.stateAP2TKI = ap2tki.map((item) => ({
          id: item.companies_id,
          name: item.companies_name,
          total: parseInt(item.total),
          urlId: "",
          used: 0,
        }));
      } catch (error) {
        console.log(error);
      }

      try {
        const responseVps: AxiosResponse<ApiResponse<any>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `dashboards/master_vps`
          );

        const { vps } = responseVps.data.data || [];

        this.stateVps = vps.map((item) => ({
          id: item.companies_id,
          name: item.companies_name,
          total: parseInt(item.total),
          urlId: "",
          used: 0,
        }));
      } catch (error) {
        console.log(error);
      }

      try {
        const responseSvts: AxiosResponse<ApiResponse<any>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `dashboards/master_svts`
          );

        const { svts } = responseSvts.data.data || [];

        this.stateSvts = svts.map((item) => ({
          id: item.companies_id,
          name: item.companies_name,
          total: parseInt(item.total),
          urlId: "",
          used: 0,
        }));
      } catch (error) {
        console.log(error);
      }

      try {
        const responseJobSeekers: AxiosResponse<ApiResponse<any>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `dashboards/master_job_seeker`
          );

        const { job_seekers } = responseJobSeekers.data.data || [];

        this.stateJobSeekers = job_seekers;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
