<template>
  <div class="card rounded-lg mb-4">
    <div class="card-body p-0">
      <div class="card-px my-2">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align">
            <h2 class="fw-bolder">{{ title }}</h2>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column
            prop="no"
            label="#"
            min-width="20"
            type="index"
            align="center"
          />
          <el-table-column
            prop="name"
            label="Nama Perusahaan"
            max-width="200"
          />
          <el-table-column prop="total" label="Total" min-width="30"
            ><template #default="scope">
              <div
                class="
                  d-flex
                  wrapper-total
                  align-items-center
                  justify-content-center
                "
              >
                {{ scope.row.total }}
              </div>
            </template></el-table-column
          >
        </el-table>
      </div>
    </div>
    <div class="card-footer p-0">
      <div class="d-flex justify-content-center">
        <div
          class="d-flex gap-2 btn-next align-items-center"
          @click="handleViewDetail"
        >
          <span class="fw-bolder">Lihat Detail</span>
          <i class="fas fa-arrow-right fs-3 me-3"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import router from "@/router";
import { MonitoringKuotaItem } from "@/core/types/gws-master/dashboard/MonitoringKuotaItem";

export default defineComponent({
  name: "CardStatus",
  props: {
    title: String,
    tableData: {
      type: Array,
      default: [] as MonitoringKuotaItem[],
    },
    detailLink: String,
    tableHeader: {
      type: Array,
      default: null,
    },
  },
  components: {},
  methods: {
    handleViewDetail() {
      if (this.detailLink) {
        router.push(this.detailLink);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.btn-next {
  cursor: pointer;
  padding: 10px;
}
.wrapper-total {
  border: 0.76px solid #00119e;
  color: #00119e;
  background-color: #f0f9ff;
  border-radius: 10px;
  padding: 2px;
}
</style>
