import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6" }
const _hoisted_3 = { class: "col-lg-6" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-lg-4" }
const _hoisted_6 = { class: "col-lg-4" }
const _hoisted_7 = { class: "col-lg-4" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-lg-12" }

import { useDashboardStore } from "@/store/pinia/gws-master/dashboard/useDashboardStore";
import CardMonitoring from "./components/CardMonitoring.vue";
import CardStatus from "./components/CardStatus.vue";
import CardStatusCPMI from "./components/CardStatusCPMI.vue";
import { ref, computed, onBeforeMount, onMounted } from "vue";

//init

export default _defineComponent({
  setup(__props) {

const dashboardStore = useDashboardStore();

const displayJobFairs = computed(() => {
  return dashboardStore.displayJobFairs;
});

const displayP3MI = computed(() => {
  return dashboardStore.displayP3MI;
});

const displayAP2TKI = computed(() => {
  return dashboardStore.displayAP2TKI;
});

const displayVps = computed(() => {
  return dashboardStore.displayVps;
});

const displaySvts = computed(() => {
  return dashboardStore.displaySvts;
});

const displayJobSeekers = computed(() => {
  return dashboardStore.displayJobSeekers;
});

//refs
const kuotaBursaHeader = ref([
  {
    name: "#",
    key: "number",
    sortable: false,
    columnWidth: 20,
  },
  {
    name: "Nama Perusahaan",
    key: "company",
    sortable: false,
    width: 100,
  },
  {
    name: "Kuota",
    key: "quota",
    sortable: false,
    width: 20,
  },
  {
    name: "Total",
    key: "total",
    sortable: false,
  },
]);

const statusHeader = ref([
  {
    name: "#",
    key: "number",
    sortable: false,
    columnWidth: 20,
  },
  {
    name: "Nama Perusahaan",
    key: "company",
    sortable: false,
    width: 100,
  },
  {
    name: "Jumlah",
    key: "total",
    sortable: false,
  },
]);

// lifecycle hook
onBeforeMount(() => {
  reload();
});

// methods
const reload = async () => {
  await dashboardStore.getDashboardData();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(CardMonitoring, {
          title: "Monitoring Kuota Bursa",
          "detail-link": "/super-admin/quota-config",
          "table-header": kuotaBursaHeader.value,
          "table-data": _unref(displayJobFairs)
        }, null, 8, ["table-header", "table-data"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(CardMonitoring, {
          title: "Monitoring Kuota P3MI",
          "detail-link": "/super-admin/quota-config",
          "table-header": kuotaBursaHeader.value,
          "table-data": _unref(displayP3MI)
        }, null, 8, ["table-header", "table-data"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(CardStatus, {
          title: "Status Update P4MI",
          "detail-link": "asd",
          "table-header": statusHeader.value,
          "table-data": _unref(displayAP2TKI)
        }, null, 8, ["table-header", "table-data"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(CardStatus, {
          title: "Status Update",
          "detail-link": "asd",
          "table-header": statusHeader.value,
          "table-data": _unref(displayVps)
        }, null, 8, ["table-header", "table-data"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(CardStatus, {
          title: "Monitoring Status Pre Clerance SVTS",
          "detail-link": "asd",
          "table-header": statusHeader.value,
          "table-data": _unref(displaySvts)
        }, null, 8, ["table-header", "table-data"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(CardStatusCPMI, {
          title: "Monitoring Status Pencari Kerja",
          "detail-link": "/super-admin/master-data/cpmi",
          "table-header": statusHeader.value,
          "table-data": _unref(displayJobSeekers)
        }, null, 8, ["table-header", "table-data"])
      ])
    ])
  ]))
}
}

})